@import url(https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css);
.screen a {
  display: contents;
  text-decoration: none;
}

.container-center-horizontal {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  pointer-events: none;
  width: 100%;
}

.container-center-horizontal > * {
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  pointer-events: auto;
}

* {
  box-sizing: border-box;
}

/* screen - honeyfarm-raendingpeiji-web */

@media (min-width: 600px) {
.honeyfarm-raendingpeiji-web {
  background-color: #ffdeac;
/*  background-image: url(../bg.png);*/
/*  background-size: 100% 100%;*/
/*  background-repeat: repeat;*/
/*  background-attachment: fixed;*/
  height: 1400px;
  mix-blend-mode: normal;
  overflow: hidden;
  overflow-x: hidden;
  position: relative;
  width: 100%;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  display: -webkit-flex;
  display: flex;
}

.honeyfarm-raendingpeiji-web .group-621-Fpil7x {
  background-color: transparent;
  height: 700px;
/*  left: 600px;*/
  position: absolute;
  margin: auto;
  top: 140px;
  width: 706px;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.honeyfarm-raendingpeiji-web .group-614-KBlgyE {
  -webkit-align-items: flex-start;
          align-items: flex-start;
  background-color: transparent;
  background-image: url(/static/media/group-610@1x.58c936bc.png);
  background-size: 100% 100%;
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  height: 500px;
  -webkit-justify-content: center;
          justify-content: center;
  left: 100px;
  width: 500px;
  padding: 140.3px 0px;
  position: absolute;
  top: 0px;
/*  width: auto;*/
  justify-content: center;
}

.honeyfarm-raendingpeiji-web .group-611-oY6AC8 {
  background-color: transparent;
  -webkit-flex-shrink: 1;
          flex-shrink: 1;
  height: 60px;
  position: relative;
  width: 326px;
}

.honeyfarm-raendingpeiji-web .group-619-KBlgyE {
  -webkit-align-items: flex-start;
          align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  height: 400px;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
/*  min-width: 672px;*/
  width: 700px;
  position: absolute;
  top: 240px;
}

.honeyfarm-raendingpeiji-web .group-616-hXbo4M {
  background-color: transparent;
  -webkit-flex-shrink: 1;
          flex-shrink: 1;
  height: 96px;
  margin-left: 0px;
  margin-top: -0.08px;
  position: relative;
  width: 143px;
}

.honeyfarm-raendingpeiji-web .group-615-hXbo4M {
  background-color: transparent;
  -webkit-flex-shrink: 1;
          flex-shrink: 1;
  height: 96px;
  margin-left: 33px;
  margin-top: -0.08px;
  position: relative;
  width: 142px;
}

.honeyfarm-raendingpeiji-web .group-617-hXbo4M {
  background-color: transparent;
  -webkit-flex-shrink: 1;
          flex-shrink: 1;
  height: 96px;
  margin-left: 34px;
  margin-top: -0.08px;
  position: relative;
  width: 142px;
}

.honeyfarm-raendingpeiji-web .group-618-hXbo4M {
  background-color: transparent;
  -webkit-flex-shrink: 1;
          flex-shrink: 1;
  height: 96px;
  margin-left: 33px;
  margin-top: -0.08px;
  position: relative;
  width: 143px;
}

.honeyfarm-raendingpeiji-web .group-620-KBlgyE2 {
  background-color: transparent;
  height: 36px;
  position: absolute;
  left: 236px;
  top: 330px;
  width: 300px;
}

.honeyfarm-raendingpeiji-web .group-620-KBlgyE {
  background-color: transparent;
  height: 36px;
  width: 36px;
  margin: 0px 10px 0px 10px;
}

.honeyfarm-raendingpeiji-web .group-623-Fpil7x {
  -webkit-align-items: center;
          align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  height: 540px;
  -webkit-justify-content: center;
          justify-content: center;
/*  left: 580px;*/
  position: absolute;
  top: 580px;
}

.honeyfarm-raendingpeiji-web .group-613-NBFikR {
  background-color: transparent;
  -webkit-flex-shrink: 1;
          flex-shrink: 1;
  height: 540px;
  position: relative;
  width: 402px;
  left: 30px;
  -webkit-justify-content: center;
          justify-content: center;
}

.honeyfarm-raendingpeiji-web .group-609-07hqRu {
  background-color: transparent;
  height: 240px;
  left: -1px;
  position: absolute;
  top: -0px;
/*  width: 458px;*/
}

.honeyfarm-raendingpeiji-web .asset-10-1-07hqRu {
  background-color: transparent;
  height: 80px;
  left: 220px;
  mix-blend-mode: normal;
  object-fit: cover;
  position: absolute;
  top: 39px;
  width: 80px;
}

.honeyfarm-raendingpeiji-web .asset-10-2-07hqRu {
  background-color: transparent;
  height: 70px;
  left: 150px;
  mix-blend-mode: normal;
  object-fit: cover;
  position: absolute;
  top: 92px;
  width: 70px;
}

.honeyfarm-raendingpeiji-web .asset-43x-1-Fpil7x {
  background-color: transparent;
  height: 122px;
/*  left: 353px;*/
  mix-blend-mode: normal;
  object-fit: cover;
  position: absolute;
  top: -22px;
  width: 1105px;
}

.honeyfarm-raendingpeiji-web .asset-154x-Fpil7x {
  background-color: transparent;
  height: 96px;
/*  left: 700px;*/
  mix-blend-mode: normal;
  object-fit: cover;
  position: absolute;
  top: 100px;
  width: 475px;
}

}



@media (max-width: 600px) {
.honeyfarm-raendingpeiji-web {
  background-color: #ffdeac;
  background-image: url(/static/media/bg.1e3cf1fb.png);
  background-size: 100% 100%;
  height: 787px;
  mix-blend-mode: normal;
  overflow: hidden;
  overflow-x: hidden;
  position: relative;
  width: 100%;
/*  align-items: center;*/
  -webkit-justify-content: center;
          justify-content: center;
  display: -webkit-flex;
  display: flex;
}

.honeyfarm-raendingpeiji-web .group-621-Fpil7x {
  background-color: transparent;
  height: 700px;
/*  left: 600px;*/
  position: absolute;
  margin: auto;
  top: 47px;
  width: 706px;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.honeyfarm-raendingpeiji-web .group-614-KBlgyE {
  -webkit-align-items: flex-start;
          align-items: flex-start;
  background-color: transparent;
  background-image: url(/static/media/group-610@1x.58c936bc.png);
  background-size: 100% 100%;
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  height: 500px;
  -webkit-justify-content: center;
          justify-content: center;
  left: 100px;
  width: 500px;
  padding: 140.3px 0px;
  position: absolute;
  top: 0px;
/*  width: auto;*/
  justify-content: center;
}

.honeyfarm-raendingpeiji-web .group-611-oY6AC8 {
  background-color: transparent;
  -webkit-flex-shrink: 1;
          flex-shrink: 1;
  height: 40px;
  position: relative;
/*  width: 260px;*/
}

.honeyfarm-raendingpeiji-web .group-619-KBlgyE {
  -webkit-align-items: flex-start;
          align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  height: 400px;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
/*  min-width: 672px;*/
  width: 700px;
  position: absolute;
  top: 240px;
}

.honeyfarm-raendingpeiji-web .group-616-hXbo4M {
  background-color: transparent;
  -webkit-flex-shrink: 1;
          flex-shrink: 1;
  height: 96px;
  margin-left: 0px;
  margin-top: -0.08px;
  position: relative;
  width: 143px;
}

.honeyfarm-raendingpeiji-web .group-615-hXbo4M {
  background-color: transparent;
  -webkit-flex-shrink: 1;
          flex-shrink: 1;
  height: 96px;
  margin-left: 33px;
  margin-top: -0.08px;
  position: relative;
  width: 142px;
}

.honeyfarm-raendingpeiji-web .group-617-hXbo4M {
  background-color: transparent;
  -webkit-flex-shrink: 1;
          flex-shrink: 1;
  height: 96px;
  margin-left: 34px;
  margin-top: -0.08px;
  position: relative;
  width: 142px;
}

.honeyfarm-raendingpeiji-web .group-618-hXbo4M {
  background-color: transparent;
  -webkit-flex-shrink: 1;
          flex-shrink: 1;
  height: 96px;
  margin-left: 33px;
  margin-top: -0.08px;
  position: relative;
  width: 143px;
}

.honeyfarm-raendingpeiji-web .group-620-KBlgyE2 {
  background-color: transparent;
  height: 36px;
  position: absolute;
  left: 236px;
  top: 330px;
  width: 300px;
}

.honeyfarm-raendingpeiji-web .group-620-KBlgyE {
  background-color: transparent;
  height: 36px;
  width: 36px;
  margin: 0px 10px 0px 10px;
}

.honeyfarm-raendingpeiji-web .group-623-Fpil7x {
  -webkit-align-items: center;
          align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  height: 540px;
  -webkit-justify-content: center;
          justify-content: center;
/*  left: 580px;*/
  position: absolute;
  top: 440px;
}

.honeyfarm-raendingpeiji-web .group-613-NBFikR {
  background-color: transparent;
  -webkit-flex-shrink: 1;
          flex-shrink: 1;
  height: 540px;
  position: relative;
  width: 402px;
  left: 30px;
  -webkit-justify-content: center;
          justify-content: center;
}

.honeyfarm-raendingpeiji-web .group-609-07hqRu {
  background-color: transparent;
  height: 240px;
  left: -1px;
  position: absolute;
  top: -0px;
/*  width: 458px;*/
}

.honeyfarm-raendingpeiji-web .asset-10-1-07hqRu {
  background-color: transparent;
  height: 80px;
  left: 220px;
  mix-blend-mode: normal;
  object-fit: cover;
  position: absolute;
  top: 39px;
  width: 80px;
}

.honeyfarm-raendingpeiji-web .asset-10-2-07hqRu {
  background-color: transparent;
  height: 70px;
  left: 150px;
  mix-blend-mode: normal;
  object-fit: cover;
  position: absolute;
  top: 92px;
  width: 70px;
}

.honeyfarm-raendingpeiji-web .asset-43x-1-Fpil7x {
  background-color: transparent;
  height: 122px;
/*  left: 353px;*/
  mix-blend-mode: normal;
  object-fit: cover;
  position: absolute;
  top: -22px;
  width: 1105px;
}

.honeyfarm-raendingpeiji-web .asset-154x-Fpil7x {
  background-color: transparent;
/*  height: 96px;*/
/*  left: 700px;*/
  mix-blend-mode: normal;
  object-fit: cover;
  position: absolute;
  top: 120px;
  width: 300px;
}

}

:root { 
 
 
}

